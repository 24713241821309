<template>
<div class="blobs-animados">
  <div class="blob1"></div>
  <div class="blob2"></div>
  <div class="blob3"></div>
  <div class="blob4"></div>

</div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    }
  },
  computed: {
    cssVars() {
      return {
        "--detalle-color": `#${this.color}`
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.blobs-animados {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  animation: blob_grupo 2s infinite alternate;
  * {
      background: #401386;
  }

}
.blob1 {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 3;
  animation: blob_1 30s ease infinite alternate-reverse;
  height: 100px;
  width: 250px;
  border-radius: 200px;

}
.blob2 {
  bottom: 150px;
  left: 0;
  position: absolute;
  transition: 1s;
  animation: blob_2 20s ease infinite alternate-reverse;
  height: 100px;
  width: 350px;
  border-radius: 200px;
  z-index: 3;
}
.blob3 {
  bottom: 300px;
  left: 0;
  position: absolute;
  transition: 1s;
  animation: blob_3 37s ease infinite alternate-reverse;
  height: 100px;
  width: 100px;
  border-radius: 200px 200px 200px 0;
  z-index: 3;
}
.blob4 {
  bottom: 450px;
  left: 0;
  position: absolute;
  transition: 1s;
  animation: blob_4 27s ease infinite alternate-reverse;
  height: 100px;
  width: 300px;
  z-index: 3;
  border-radius: 200px 200px 200px 0;
}

@keyframes blob_1 {
  0% {
    transform:  translate(0px, 0px);
  }
  100% {
    transform:  translate(100px, 0px);
  }
}
@keyframes blob_2 {
  0% {
    transform:  translate(0px, 0px);
  }
  100% {
    transform:  translate(200px, 0px);
  }
}
@keyframes blob_3 {
  0% {
    transform:  translate(0px, 0px);
  }
  100% {
    transform:  translate(300px, 0px);
  }
}
@keyframes blob_4 {
  0% {
    transform:  translate(-100px, 0px);
  }
  100% {
    transform:  translate(100px, 0px);
  }
}
</style>
